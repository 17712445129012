export interface SessionError {
  errorCode?: ErrorCode;
  details?: Details;
  message: string;
}

export enum ErrorCode {
  FORM_ERROR = '5001',
}

interface Details {
  fieldErrors?: FieldError[];
}

interface FieldError {
  fieldName?: string;
  error?: string;
}

